$(document).ready(function() {

  $('.colapsables-cargables')
    .on('click.cargar_colapsable', '.cargar', cargarColapsables);

});

function cargarColapsables(e) {
  e.preventDefault();

  $(this).after('<small>Cargando...</small>'); // Añade indicador de carga

  var padre = $(this).parents('.panel-heading').first();
  var objetivo = padre.parent().find('.panel-collapse').children().first();
  var self = this;
  $.ajax({
    method  : 'GET',
    url     : $(this).data('url'),
    success : function(html) {
      mostrarColapsables(self, objetivo, html);
    },
    error : function() {
      notificarErrorDeCarga(self, objetivo);
    }
  });
}

function mostrarColapsables(elemento, objetivo, html) {
  objetivo.html(html);
  objetivo.parent().collapse();
  mostrarDesplegable(elemento, objetivo);
}

function notificarErrorDeCarga(elemento, objetivo) {
  objetivo.html(
    '<div class="alert alert-danger">' +
    '  <p>' +
    '    No se ha podido obtener el contenido que solicitaste. ' +
    '    Comprueba tu conexión a Internet e intenta de nuevo más tarde.' +
    '  </p>' +
    '</div>');
  mostrarDesplegable(elemento, objetivo);
}

function mostrarDesplegable(elemento, objetivo) {
  objetivo.parent().collapse();

  $(elemento).removeClass('cargar'); // Evita repetir la carga
  $(elemento).parent().attr('data-toggle', 'collapse');
  $(elemento).next().remove(); // Remueve el indicador de carga
}
