function AdminPermisos(tabla, opciones) {
  this.tabla = tabla;
  this.clasePositiva = opciones.clasePositiva || 'btn-success';
  this.claseNegativa = opciones.claseNegativa || 'btn-danger';
  this.eliminarPermisoUrl = opciones.eliminarPermisoUrl;
  this.crearPermisoUrl = opciones.crearPermisoUrl;
  this.token = opciones.token;

  this.inicializar();
}

AdminPermisos.prototype.inicializar = function() {
  this.inicializarPositivos();
  this.inicializarNegativos();
};

AdminPermisos.prototype.inicializarPositivos = function() {
  var admin = this;

  admin.tabla.on('click', '.' + admin.clasePositiva, function(e) {
    e.preventDefault();

    var boton = $(this);

    boton.prop('disabled', true);

    admin.eliminarPermiso(boton.attr('data-permiso-id'), function(permiso) {
      admin.cambiarBoton(boton, permiso, false).prop('disabled', false);;
    });
  });
};

AdminPermisos.prototype.inicializarNegativos = function() {
  var admin = this;

  admin.tabla.on('click', '.' + admin.claseNegativa, function(e) {
    e.preventDefault();

    var boton = $(this);
    console.log(boton);

    boton.prop('disabled', true);

    var datos = { tipo: boton.attr('data-tipo'),
                  periodo_id: boton.attr('data-periodo-id'),
                  _token: admin.token };

    admin.crearPermiso(datos, function(permiso) {
      admin.cambiarBoton(boton, permiso, true).prop('disabled', false);
    });
  });
};

AdminPermisos.prototype.cambiarBoton = function(boton, permiso, estado) {
  if (estado) {
    boton.attr('data-permiso-id', permiso.id);
  } else {
    boton.attr('data-periodo-id', permiso.periodo_id)
         .attr('data-tipo', permiso.tipo);
  }

  return boton.toggleClass(this.claseNegativa)
              .toggleClass(this.clasePositiva);
};

AdminPermisos.prototype.eliminarPermiso = function(permisoID, callback) {
  $.ajax({
    method: 'DELETE',
    url: this.eliminarPermisoUrl.replace('id', permisoID),
    success: callback,
    error: function() {
      alert('Ocurrió un error. Por favor, consulte directamente con el administrador.');
    }
  });
};

AdminPermisos.prototype.crearPermiso = function(datos, callback) {
  $.ajax({
    method: 'POST',
    url: this.crearPermisoUrl,
    data: datos,
    success: callback,
    error: function() {
      alert('Ocurrió un error. Por favor, consulte directamente con el administrador.');
    }
  });
};

